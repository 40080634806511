import './welcome.css'

export default function Welcome() {
    return (
        <div className='welcome'>
            <h1>Myong O, LCSW</h1>
            <h2 className='subtitle'><i>psychotherapy and life coaching</i></h2>
        </div>
    )
}
